<template>
  <div>
    <div class="cover">
      <img :src="require('@/assets/we/banner.png')" alt="">
    </div>

    <div class="topTxt">
      <div class="hs">
        <div class="title">企业简介</div>
        <div class="txt">
          <p>
            四川省米果码信息技术有限公司是一家专业从事全流程追溯的高新技术企业。公司将自动识别技术、自动控制技术、互联网技术、物联网技术相互结合运用，通过对单一商品的赋码，实现从原料入库、领料生产、质检入库、分销流转、智能促销等各个环节的数据采集和追踪，从而打造商品全链条数字化管理和营销管理。
          </p>
          <p> 我们的服务遍布80%的城市，是一家以产品技术研发为导向的企业。在不同行业、不同领域、不同场景下提供了全方位的一物一码数字解决方案与服务。 </p>
        </div>
      </div>
    </div>


    <div class="cenBox">
      <div v-for="(v,i) in cenBoxList" :key="i">
        <span :class="['icon' ,v.icon]"></span>
        <div class="t">
          {{ v.title }}
          <div>{{ v.eg }}</div>
        </div>
      </div>
    </div>


    <div class="service">
      <div class="hs">
        <div class="title">我们的服务遍布全国80%以上省份</div>
        <div class="txt">
          在不同行业、不同领域、不同场景下提供了全方位的解决方案与服务
        </div>
      </div>

      <img src="../../assets/we/dt.jpg" alt="">


      <div class="hs">
        <div class="title">资质荣誉</div>
        <div class="txt">
          我们已经获得了多项荣誉，荣获专利技术与证书
        </div>
      </div>

    </div>


    <div class="honor">
      <img src="../../assets/we/zz.png" alt="">
    </div>


    <div class="trust">

      <div class="hs">
        <div class="title">客户信赖</div>
        <div class="txt">
          万物互联，价值共享
        </div>
      </div>

      <img src="../../assets/we/kh.png" alt="">


      <div class="hs">
        <div class="title">客户信赖</div>
        <div class="txt">
          万物互联，价值共享
        </div>
      </div>
    </div>


    <div class="we">

      <div class="box">

        <img src="../../assets/we/d.png" alt="">

        <div class="contact">
          <div class="itme"><span class="icon icondianhua"></span>
            <span>400-099-0767</span>
          </div>
          <div class="itme"><span class="icon icondizhi"/> <span>四川省成都市新时代广场22楼</span></div>
          <div class="itme"><span class="icon icongongzhonghao"/> <img src="../../assets/gzh.png" alt=""></div>
          <div class="itme">
            <div>
              <img src="../../assets/home/wx.png" alt="">
              <img src="../../assets/home/wb.png" alt="">
              <img src="../../assets/home/zf.png" alt="">
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>


import { scrolTop } from "@/utils/ decorator";

export default {
  components: {},
  data() {
    return {
      cenBoxList: [
        { icon: 'iconhangyeyoushi', title: '溯源集成服务', eg: 'TRACEABILITY SYSTEM' },
        { icon: 'iconruanjiankaifa', title: '软件开发', eg: 'SOFTWARE DEVELOPMENT' },
        { icon: 'iconerweima', title: '喷码机', eg: 'INKJET PRINTER' },
        { icon: 'iconshebeikaifa', title: '采集设备', eg: 'ACQUISITION EQUIPMENT' },
      ]
    }
  },
  name: "we",
  @scrolTop
  created() {
  },
}
</script>

<style lang="less" scoped>


.cover {
  width: 100%;
  height: 528px;
  img {
    width: 100%;
    height: 100%;
  }
}

.topTxt {
  padding: 100px 0 30px;
}

.hs {
  width: 1200px;
  margin: 0 auto;
  text-align: center;

  .title {
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .txt {
    text-align: left;
    margin-top: 30px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;

    p {
      text-indent: 50px;
      line-height: 45px;
    }

  }
}


.cenBox {
  width: 100%;
  background: #F6F6F6;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 70px 230px;

  > div {
    width: 215px;
    padding: 45px 0;
    background: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px 26px 1px rgba(0, 0, 0, 0.03);

    .icon {
      width: 54px;
    }

    .t {
      margin-top: 40px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      > div {
        margin-top: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FF5B5B;
      }
    }

  }

}

.service {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  padding: 100px 0;

  .txt {
    text-align: center;
  }

  img {
    margin-top: 100px;
    width: 100%;
    height: 835px;
  }
}


.honor {
  text-align: center;
  background: #F6F6F6;
  padding: 66px 0;
}


.trust {
  padding: 100px 0 30px;
  text-align: center;

  .txt {
    text-align: center;
  }

  img {
    width: 1500px;
    margin: 80px 0 120px;
  }
}


.we {
  text-align: center;
  background: #F6F6F6;
  display: flex;
  justify-content: center;
  padding: 85px 0;

  .box {
    width: 700px;
    display: flex;
    justify-content: space-between;

    img {
      width: 372px;
      height: 268px;
    }

    .contact {
      .itme {
        display: flex;
        margin: 20px 0;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;

        .icon {
          font-size: 23px;
          color: #fd8888;
          margin-right: 10px;
        }

        img {
          width: 84px;
          height: 79px;
        }

        > div {
          img {
            width: 34px;
            height: 34px;
            margin: 0 10px;
          }
        }
      }
    }
  }
}

</style>
